import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Blobs from "../components/blobs"
import Img from "gatsby-image"
import { Link } from "gatsby"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />
      {
        data.allSanityProjectAnnukka.edges.map(({node}) => (
          <Link className="frontLink" key={node.id} to={'/' + node.slug.current}>
            <Img className="frontImage" fluid={node.mainImage.asset.fluid}></Img>
          </Link>
        ))
      }
    <Blobs></Blobs>
    <Link className="aboutLink" to="/about/">About</Link>
  </Layout>
)

export default IndexPage

export const query = graphql `
query MyQuery {
  allSanityProjectAnnukka(sort: {fields: publishedAt, order: DESC}) {
    edges {
      node {
        id
        title
        slug {
          _key
          _type
          current
        }
        publishedAt
        author {
          id
          name
        }
        mainImage {
          _key
          _type
          asset {
            id
            url
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawBody
      }
    }
  }
}
`
